$(function () {
  try {
    Typekit.load();
  } catch (e) { }
});

jQuery(document).ready(function ($) {

  // TOGGLE MOBILE NAV
  $('.btn-mobile-open-nav').click(function () {
    $('.mobile-menu').toggle();
    // $('span').toggleClass('menu-open');
    // $('span').toggleClass('menu-close');
  });

  $('.btn-mobile-close-nav').click(function () {
    $('.mobile-menu').toggle();
  });

  $('.li1.has_children').on('click', function() {
    $(this).find('li').toggleClass('show');
    $(this).find('span i').toggleClass('fa-minus');
  });

  // SEARCH TOGGLE START
  $('.search-btn').click(function (e) {
    e.stopPropagation();
    $('.search-box-container').slideToggle("fast");
  });

  $('.search-close').click(function () {
    $('.search-box-container').hide();
  });
  // SEARCH TOGGLE END

  //SEARCH FUNCTIONALITY START - DESKTOP
  $(document).ready(function () {
    $('.search-box button').on('click', function () {
      var query = $('.search-box input').val();
      window.location.href = "/Search/?q=" + query;
      return false;
    });

    $('.search-box input').keypress(function (e) {
      if (e.which == 13) {
        var query = $('.search-box input').val();
        window.location.href = "/Search/?q=" + query;
        return false;
      }
    });
  });
  //SEARCH FUNCTIONALITY - DESKTOP END

  //SEARCH FUNCTIONALITY START - MOBILE
  $(document).ready(function () {
    $('.mobile-search-box button').on('click', function () {
      var query = $('.mobile-search-box input').val();
      window.location.href = "/Search/?q=" + query;
      return false;
    });

    $('.mobile-search-box input').keypress(function (e) {
      if (e.which == 13) {
        var query = $('.mobile-search-box input').val();
        window.location.href = "/Search/?q=" + query;
        return false;
      }
    });
  });
  //SEARCH FUNCTIONALITY - MOBILE END

  //Tabbing for Main Nav
  $('.nav-primary .li1').focus(function () {
    $(this).addClass('focused');
  });

  $('.nav-primary .li1 .a1').focus(function () {
    $(this).parent().addClass('focused');
  });

  $('.nav-primary .li1 .a1').focusout(function () {
    $(this).parent().removeClass('focused');
  });

  $('.nav-primary .li1 .ul2 > li:last-child').focusout(function () {
    $(this).parent().parent().removeClass('focused');
  });

  $(function() {
    $('.li1.has_children').attr('tabindex', function(index, attr) {
      return 0;
  });
    $('.li1.has_children a.a1').attr('tabindex', function(index, attr) {
        return -1;
    });
  });

  //Span added to mobile nav items with children
  $('.mobile-menu .ul1 > li').each(function () {
    if ($(this).hasClass('has_children')) {
      $('> a', this).after('<span tabindex="0" aria-label="View Child Pages"><i class="fa fa-plus"></i></span>');
    }
  });

  $('.li1.has_children span i').on('click', function() {
    $(this).toggleClass('fa-plus');
  });

  $(function () {
    $('.li1.has_children span').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $(this).children('span i').toggleClass('fa-minus');
        $(this).find('li').toggle();
      }
    });
  });

  $(function () {
    $('.li1.has_children').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        // $(this).toggleClass('minus');
        $(this).find('li').toggle();
      }
    });
  });

  // $('.li1.has_children').on('click', function() {
  //   $(this).children('span i').toggleClass('fa-minus');
  // });

  //Span added to mobile nav items with children END

  //Homepage Featured Item Lists to Select Box START
  if ($(window).width() < 884) {
    $('ul.categories').each(function(){
      var list=$(this),
          select=$(document.createElement('select')).insertBefore($(this).hide()).change(function(){
        window.location.href=$(this).val();
      });
      $('>li a', this).each(function(){
        var option=$(document.createElement('option'))
        .appendTo(select)
        .val(this.href)
        .html($(this).html());
        if($(this).attr('class') === 'selected'){
          option.attr('selected','selected');
        }
      });
      list.remove();
      $(select).wrap("<div class='dropdown-container'></div>");
    });
  }

  $('.dropdown-container select').prepend($('<option selected="selected" value="1">Select one...</option>')
  );
  //Homepage Featured Item Lists to Select Box END

  // Tabs
  // The class for the container div
  var tabContainer = '.tabs';

  // The setup
  $('ul.tab-list', tabContainer).empty().attr('role', 'tablist');
  $('.tab-content', tabContainer).each(function (index) {
    var tabHeading = $(this).find("> .tab-title").html();
    var tabHeadingID = 'tab' + index;
    $('ul.tab-list', tabContainer).append('<li><a href="#' + tabHeadingID + '">' + tabHeading + '</a></li>');
    $(this).attr('id', tabHeadingID);
  });

  $('.mobile-tab').each(function (index) {
    var tabHeadingID = '#' + 'tab' + index;
    $(this).attr('href', tabHeadingID);
  });

  $('[role="tablist"] > li', tabContainer).attr('role', 'presentation');
  $('[role="tablist"] a', tabContainer).attr({
    'role': 'tab',
    'tabindex': '-1'
  });

  // Make each aria-controls correspond id of targeted section (re href)
  $('[role="tablist"] a').each(function () {
    $(this).attr(
      'aria-controls', $(this).attr('href').substring(1)
    );
  });

  // Make the first tab selected by default and allow it focus
  $('[role="tablist"] li:first-child').addClass('active');
  $('[role="tablist"] li:first-child a').attr({
    'aria-selected': 'true',
    'tabindex': '0'
  });

  // Make each section focusable and give it the tabpanel role
  $(tabContainer + ' section').attr({
    'role': 'tabpanel'
  });

  // Make first child of each panel focusable programmatically
  $(tabContainer + ' section > *:first-child').attr({
    'tabindex': '0'
  });

  // Make all but the first section hidden (ARIA state and display CSS)
  $('[role="tabpanel"]:not(:first-of-type)').attr({
    'aria-hidden': 'true'
  });

  // Change focus between tabs with arrow keys
  $('[role="tab"]').on('keydown', function (e) {

    // define current, previous and next (possible) tabs
    var tabCurrent = $(this);
    var tabPrev = $(this).parents('li').prev().children('[role="tab"]');
    var tabNext = $(this).parents('li').next().children('[role="tab"]');
    var tabTarget;

    // find the direction (prev or next)
    switch (e.keyCode) {
      case 37:
        tabTarget = tabPrev;
        break;
      case 39:
        tabTarget = tabNext;
        break;
      default:
        tabTarget = false;
        break;
    }

    if (tabTarget.length) {
      $('.tab-list li').removeClass('active');
      tabCurrent.parents('li').addClass('active');
      tabCurrent.attr({
        'tabindex': '-1',
        'aria-selected': null
      });
      tabTarget.attr({
        'tabindex': '0',
        'aria-selected': true
      }).focus();
    }

    // Hide panels
    $(tabContainer + ' [role="tabpanel"]')
      .attr('aria-hidden', 'true');

    // Show panel which corresponds to target
    $('#' + $(document.activeElement).attr('href').substring(1))
      .attr('aria-hidden', null);
  });

  // Handle click on tab to show + focus tabpanel
  $('[role="tab"]').on('click', function (e) {
    e.preventDefault();

    // remove focusability [sic] and aria-selected
    $('.tab-list li').removeClass('active');
    $('[role="tab"]').attr({
      'tabindex': '-1',
      'aria-selected': null
    });

    // replace above on clicked tab
    $(this).parents('li').addClass('active');
    $(this).attr({
      'aria-selected': true,
      'tabindex': '0'
    });

    // Hide panels
    $(tabContainer + ' [role="tabpanel"]').attr('aria-hidden', 'true');

    // show corresponding panel
    $('#' + $(this).attr('href').substring(1))
      .attr('aria-hidden', null);

  });

  // Handle click on Mobile tabs to show + focus tabpanel
  $('.mobile-tab').on('click', function (e) {
    e.preventDefault();

    // remove focusability [sic] and aria-selected
    $('.mobile-tab').attr({
      'tabindex': '0',
      'aria-selected': null
    });


    // replace above on clicked tab
    $(this).attr({
      'aria-selected': true,
      'tabindex': '0'
    });

    // Hide panels
    $('[role="tabpanel"]').attr('aria-hidden', 'true');

    // show corresponding panel
    $('#' + $(this).attr('href').substring(1))
      .attr('aria-hidden', null);

  });

  // Make first child of each panel focusable programmatically
  $('.mobile-tab:first-of-type').attr({
    'aria-selected': 'true'
  });


  // hash focus onload
  if (document.location.hash) {
    var myAnchor = document.location.hash;
    $(myAnchor).attr('tabindex', -1).on('blur focusout', function () {
      $(this).removeAttr('tabindex');
    }).focus();
  }

  //END TABS

  //Hide empty Product Features if empty in Tabs
  if ($(".tab-content .product-content h3:contains('{{feature-title}}')").size()) {
    $(".product-content h3").each(function() {
      if ($(this).html() === "{{feature-title}}") {
        $(this).parent().parent().hide();
      }
    });
  }

  if ($(".tab-content .product-content h3:contains('')").size()) {
    $(".product-content h3").each(function() {
      if ($(this).html() === "") {
        $(this).parent().parent().hide();
      }
    });
  }
  //END Hide empty Product Features if empty in Tabs

  //Wrap tabs on mobile START
  $("ul.tab-list").wrap("<div class='tab-container'></div>");
  //Wrap tabs on Mobile END

  //Add Contact Call To Action to bottom of Tab List
  $(".tab-container").append('<div class="inquiry"><span>Questions?</span><span class="contact"><a href="/contact-us">Contact Us &raquo;</a></span></div>');
  //END Add Contact Call To Action to bottom of Tab List

  //Add arrows to tab list on mobile START
  var scrollHandle = 0,
  scrollStep = 5,
  parent = $('.tab-list');

  //Start the scrolling process
  $(".pager").on("mousedown touchstart", function () {
    var data = $(this).data('scrollModifier'),
      direction = parseInt(data, 10);

    $(this).addClass('active');

    startScrolling(direction, scrollStep);
  });

  //Kill the scrolling
  $(".pager").on("mouseup touchend", function () {
    stopScrolling();
    $(this).removeClass('active');
  });

  //Actual handling of the scrolling
  function startScrolling(modifier, step) {
    if (scrollHandle === 0) {
      scrollHandle = setInterval(function () {
        var newOffset = parent.scrollLeft() + (scrollStep * modifier);

        parent.scrollLeft(newOffset);
      }, 10);
    }
  }

  function stopScrolling() {
    clearInterval(scrollHandle);
    scrollHandle = 0;
  }
  //Add arrows to Tabs on Mobile END

  // hash focus inline
  $(window).bind('hashchange', function () {
    var hash = "#" + window.location.hash.replace(/^#/, '');
    if (hash != "#") {
      $(hash).attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
    else {
      $("#header").attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
  });
});